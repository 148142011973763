import React from "react";

export default function OneContact({ name, content, icon }) {
  return (
    <div>
      <div className="flex flex-row items-center gap-2 space-y-2">
        {icon}
        <h3 className="text-xl">{name}</h3>
      </div>
      <p>{content}</p>
    </div>
  );
}
