import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";
import AdminDialog from "../AdminDialog";
import AllRealEstate from "../AllRealEstate";

export default function Admin() {
  const [loading, setLoading] = useState(true);
  const [realEstateList, setRealEstateList] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogIsNew, setDialogIsNew] = useState(true);
  const [dialogRealEstate, setDialogRealEstate] = useState(true);
  const navigate = useNavigate();

  const verifyAuth = useCallback(async () => {
    setLoading(true);
    const res = await fetch("/api/auth");
    const data = await res.json();
    setLoading(false);
    if (!data.auth) {
      navigate("/login");
    }
  }, [navigate]);

  const realEstate = async () => {
    setLoading(true);
    const res = await fetch("/api/realestate");
    const data = await res.json();
    setLoading(false);
    setRealEstateList(data);
  };

  useEffect(() => {
    verifyAuth().then(realEstate);
  }, [verifyAuth]);

  const logout = async () => {
    setLoading(true);
    const res = await fetch("/api/logout", { method: "POST" });
    setLoading(false);
    if (res.status !== 200) {
      return alert(res.status);
    }
    navigate("/login");
  };

  const editDialog = (id) => {
    setDialogIsNew(false);
    const oneRealEstate = realEstateList.find((el) => el.id === id);
    setDialogRealEstate(oneRealEstate);
    setShowDialog(true);
  };

  return (
    <div className="mt-28 md:mt-36 mb-12 mx-6 md:mx-12">
      <div className="flex flex-col items-center">
        {loading ? (
          <Icon className="animate-spin" path={mdiLoading} size={5} />
        ) : (
          <>
            <div className="flex gap-2 mb-6">
              <button
                className="px-3 py-2 rounded-xl bg-sky-500 text-white hover:bg-gray-100 hover:text-sky-500 disabled:bg-gray-300 disabled:text-gray-100 fade-transition"
                onClick={logout}
              >
                🚪 Logout
              </button>
              <button
                className="px-3 py-2 rounded-xl bg-sky-500 text-white hover:bg-gray-100 hover:text-sky-500 disabled:bg-gray-300 disabled:text-gray-100 fade-transition"
                onClick={() => {
                  setDialogIsNew(true);
                  setShowDialog(true);
                }}
              >
                ⭐ New
              </button>
            </div>
            <AllRealEstate
              realEstateList={realEstateList}
              onClick={editDialog}
            />
            {showDialog ? (
              <AdminDialog
                close={() => {
                  setShowDialog(false);
                  realEstate();
                }}
                isNew={dialogIsNew}
                data={dialogRealEstate}
              />
            ) : null}
          </>
        )}
      </div>
    </div>
  );
}
