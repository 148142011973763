import React, { useEffect, useRef, useState } from "react";

export default function AdminDialog({ close, isNew, data }) {
  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [mapQuery, setMapQuery] = useState("");
  const [mapZoom, setMapZoom] = useState("");
  const [price, setPrice] = useState("");
  const [bedrooms, setBedrooms] = useState("");
  const [bathrooms, setBathrooms] = useState("");
  const [inhabitableArea, setInhabitableArea] = useState("");
  const [landArea, setLandArea] = useState("");
  const fileInputRef = useRef(null);
  const [imageLinks, setImageLinks] = useState([]);

  useEffect(() => {
    if (!isNew) {
      setTitle(data.title);
      setLocation(data.location);
      setDescription(data.description);
      setMapQuery(data.mapQuery);
      setMapZoom(data.mapZoom);
      setPrice(data.price);
      setBedrooms(data.bedrooms);
      setBathrooms(data.bathrooms);
      setInhabitableArea(data.inhabitableArea);
      setLandArea(data.landArea);
      setImageLinks(data.imageLinks);
    }
  }, [data, isNew]);

  const upload = async (event) => {
    const formData = new FormData();
    for (let i = 0; i < event.target.files.length; i++) {
      formData.append("images", event.target.files[i]);
    }
    const res = await fetch("/api/upload", {
      method: "POST",
      body: formData,
    });
    if (res.status !== 200) {
      return alert(res.status);
    }
    const data = await res.json();
    const newImageLinks = [...imageLinks, ...data.imagePaths];
    setImageLinks(newImageLinks);
    fileInputRef.current.value = "";
  };

  const deleteUpload = async (filename) => {
    const res = await fetch("/api/upload" + filename, { method: "DELETE" });
    if (res.status !== 200) {
      return alert(res.status);
    }
    const newImageLinks = [...imageLinks];
    newImageLinks.splice(newImageLinks.indexOf(filename), 1);
    setImageLinks(newImageLinks);
  };

  const deleteAllUploads = async () => {
    for (const filename of imageLinks) {
      const res = await fetch("/api/upload" + filename, { method: "DELETE" });
      if (res.status !== 200) {
        return alert(res.status);
      }
    }
    close();
  };

  const submitNew = async () => {
    const res = await fetch("/api/new", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title,
        location,
        description,
        mapQuery,
        mapZoom,
        price,
        bedrooms,
        bathrooms,
        inhabitableArea,
        landArea,
        imageLinks,
      }),
    });
    if (res.status !== 200) {
      return alert(res.status);
    }
    close();
  };

  const deleteSubmission = async () => {
    const res = await fetch("/api/delete/" + data.id, { method: "DELETE" });
    if (res.status !== 200) {
      return alert(res.status);
    }
    await deleteAllUploads();
  };

  const update = async () => {
    const res = await fetch("/api/update", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: data.id,
        title,
        location,
        description,
        mapQuery,
        mapZoom,
        price,
        bedrooms,
        bathrooms,
        inhabitableArea,
        landArea,
        imageLinks,
      }),
    });
    if (res.status !== 200) {
      return alert(res.status);
    }
    close();
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="p-4 relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
            <div className="flex flex-col gap-2">
              <h1 className="text-3xl">
                {isNew ? "New real estate" : "Edit real estate"}
              </h1>
              <input
                className="px-3 py-2 rounded-xl outline-none shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-sky-500 focus:ring-2 fade-transition"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <input
                className="px-3 py-2 rounded-xl outline-none shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-sky-500 focus:ring-2 fade-transition"
                placeholder="Location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
              <input
                className="px-3 py-2 rounded-xl outline-none shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-sky-500 focus:ring-2 fade-transition"
                placeholder="Price"
                value={price}
                type="number"
                onChange={(e) => setPrice(e.target.value)}
              />
              <div className="flex flex-col gap-2 lg:flex-row justify-between">
                <input
                  className="max-w-40 px-3 py-2 rounded-xl outline-none shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-sky-500 focus:ring-2 fade-transition"
                  placeholder="Bedrooms"
                  value={bedrooms}
                  type="number"
                  onChange={(e) => setBedrooms(e.target.value)}
                />
                <input
                  className="max-w-40 px-3 py-2 rounded-xl outline-none shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-sky-500 focus:ring-2 fade-transition"
                  placeholder="Bathrooms"
                  value={bathrooms}
                  type="number"
                  onChange={(e) => setBathrooms(e.target.value)}
                />
                <input
                  className="max-w-40 px-3 py-2 rounded-xl outline-none shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-sky-500 focus:ring-2 fade-transition"
                  placeholder="Inhabitable m2"
                  value={inhabitableArea}
                  type="number"
                  onChange={(e) => setInhabitableArea(e.target.value)}
                />
                <input
                  className="max-w-40 px-3 py-2 rounded-xl outline-none shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-sky-500 focus:ring-2 fade-transition"
                  placeholder="Land m2"
                  value={landArea}
                  type="number"
                  onChange={(e) => setLandArea(e.target.value)}
                />
              </div>
              <textarea
                className="h-48 px-3 py-2 rounded-xl outline-none shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-sky-500 focus:ring-2 fade-transition"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <input
                className="px-3 py-2 rounded-xl outline-none shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-sky-500 focus:ring-2 fade-transition"
                placeholder="Map query"
                value={mapQuery}
                onChange={(e) => setMapQuery(e.target.value)}
              />
              <input
                className="px-3 py-2 rounded-xl outline-none shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-sky-500 focus:ring-2 fade-transition"
                placeholder="Map zoom (8-21)"
                value={mapZoom}
                type="number"
                onChange={(e) => setMapZoom(e.target.value)}
              />
              <iframe
                className="w-full aspect-3/2 border-none mt-4"
                title="Map"
                loading="lazy"
                allowfullscreen
                referrerpolicy="no-referrer-when-downgrade"
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyB5Y6FeM_D6PyXBtn_xuZcoNRjEVIzr6P8
    &q=${mapQuery || "Pilar de la Horadada"}&zoom=${mapZoom || 13}`}
              ></iframe>
              <input
                ref={fileInputRef}
                className="hidden"
                id="upload"
                type="file"
                onChange={upload}
                accept="image/*"
                multiple
              />
              <button
                className="px-3 py-2 rounded-xl bg-sky-500 text-white hover:bg-gray-100 hover:text-sky-500 disabled:bg-gray-300 disabled:text-gray-100 fade-transition"
                onClick={() => document.getElementById("upload").click()}
              >
                🖼️ Add images
              </button>
              {imageLinks.length > 0 ? (
                <p>Double-click an image to delete it</p>
              ) : null}
              {imageLinks.map((el) => (
                <img
                  key={el}
                  className="cursor-pointer hover:ring-2 ring-red-500 rounded-xl fade-transition"
                  src={el}
                  alt=""
                  onDoubleClick={() => deleteUpload(el)}
                />
              ))}
              <div className="flex flex-row justify-between">
                {isNew ? (
                  <button
                    className="px-3 py-2 rounded-xl bg-red-500 text-white hover:bg-gray-100 hover:text-red-500 disabled:bg-gray-300 disabled:text-gray-100 fade-transition"
                    onClick={deleteAllUploads}
                  >
                    🗑️ Cancel
                  </button>
                ) : null}
                {isNew ? (
                  <button
                    className="px-3 py-2 rounded-xl bg-sky-500 text-white hover:bg-gray-100 hover:text-sky-500 disabled:bg-gray-300 disabled:text-gray-100 fade-transition"
                    onClick={submitNew}
                  >
                    ✅ New
                  </button>
                ) : null}
                {!isNew ? (
                  <button
                    className="px-3 py-2 rounded-xl bg-red-500 text-white hover:bg-gray-100 hover:text-red-500 disabled:bg-gray-300 disabled:text-gray-100 fade-transition"
                    onClick={deleteSubmission}
                  >
                    🗑️ Delete
                  </button>
                ) : null}
                {!isNew ? (
                  <button
                    className="px-3 py-2 rounded-xl bg-sky-500 text-white hover:bg-gray-100 hover:text-sky-500 disabled:bg-gray-300 disabled:text-gray-100 fade-transition"
                    onClick={update}
                  >
                    ✅ Update
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
