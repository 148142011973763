import React from "react";
import Navbar from "../Navbar";
import AllContacts from "../AllContacts";
import Article from "../Article";

export default function AboutUs() {
  return (
    <>
      <Navbar />
      <div className="mt-28 md:mt-36 mb-12 mx-6 md:mx-12">
        <div className="flex flex-col lg:grid grid-cols-3 gap-16">
          <Article title="Kdo jsme" content="Jsme skupina realitních makléřů  pracujících a žijících ve Španělsku více jak 20 let. Naše dlouholetá zkušenost a místní znalost Vám dává jistotu spolehlivého nákupu Vaší vysněné nemovitosti." />
          <Article title="Co nabízíme" content="V našem výběru nemovitostí naleznete apartmány, rodinné domy, luxusní vily, komerční nemovitosti a pozemky." />
          <Article title="Co zajišťujeme" content="Naše kompletní administrace od vyřízení NIE, založení španělského bankovního účtu, sepsání rezervační smlouvy, plné moci, kupní smlouvy až po vklad na katastrální úřad, je zajištěna prostřednictvím našeho právního zástupce a místní notářské kanceláře." />
        </div>
      </div>
      <AllContacts />
    </>
  );
}
