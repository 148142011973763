import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import AboutUs from "./pages/AboutUs";
import RealEstate from "./pages/RealEstate";
import Listing from "./pages/Listing";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import Useful from "./pages/Useful";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RealEstate />,
  },
  {
    path: "/aboutus",
    element: <AboutUs />,
  },
  {
    path: "/listing/:id",
    element: <Listing />,
  },
  {
    path: "/useful",
    element: <Useful />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/admin",
    element: <Admin />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
