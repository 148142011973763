import React from 'react'
import Navbar from '../Navbar'
import AllContacts from '../AllContacts'
import Article from '../Article'

export default function Useful() {
  return (
    <>
      <Navbar />
      <div className="mt-28 md:mt-36 mb-12 mx-6 md:mx-12">
        <div className="flex flex-col lg:grid grid-cols-3 gap-16">
          <Article title="Co je NIE?" content="Número de Identidad de extranjero – unikátní identifikační číslo pro cizince. Jedná se o obdobu našeho rodného čísla, které je pro nákup nemovitosti ve Španělsku nutné. S vyřízením tohoto čísla Vám rádi pomůžeme prostřednictvím našeho právního zástupce." />
          <Article title="Proč bankovní účet ve Španělsku" content="Většina úřadů zajišťujících správu nemovitostí (placení komunitních poplatků, odpady, místní daně) nebo dodavatelů energií požaduje platby ze španělského bankovního účtu. Se založením tohoto bankovního účtu Vám také rádi pomůžeme." />
          <Article title="Jaký je rezervační poplatek" content="U většiny nemovitostí je rezervační poplatek 10 % kupní ceny nemovitosti. Rezervační smlouva zajišťuje kupujícímu výhradní právo na vybranou nemovitost, která je na základě uzavření rezervační smlouvy stažena z trhu." />
          <Article title="Kdo sepisuje kupní smlouvu" content="Kupní smlouva je vyhotovena pověřeným notářem a má formu notářského zápisu. Po jejím podpisu všemi smluvními stranami notář smlouvu zaregistruje na místně příslušném katastru nemovitostí a finančním úřadu. Podpisem kupní smlouvy a složením kupní ceny se nemovitost okamžitě stává majetkem kupujícího." />
          <Article title="Kdy si můžete nemovitost převzít" content="Pokud se jedná o již dokončenou nemovitost, podpisem kupní smlouvy a složením kupní ceny se nemovitost okamžitě stává majetkem kupujícího a tento si nemovitost může okamžitě převzít (není potřeba čekat na zápis v katastru nemovitostí)." />
          <Article title="Daně a poplatky spojené s nákupem" content="Při nákupu nemovitosti kupující platí daň z nabytí nemovitosti ve výši 10 % kupní ceny nemovitost. Tato daň se netýká nákupu novostaveb, kde se kupující stává prvním majitelem. Právní poplatky za kompletní servis (rezervační smlouva, kupní smlouva, zápis na katastru nemovitostí, plné moci, převod energií) jsou ve výši 1,5 až 2 % z kupní ceny nemovitosti." />
          <Article title="Další náklady spojené s vlastnictvím nemovitosti" content="U většiny nemovitostí je rezervační poplatek 10 % kupní ceny nemovitosti. Rezervační smlouva zajišťuje kupujícímu výhradní právo na vybranou nemovitost, která je na základě uzavření rezervační smlouvy stažena z trhu." />
        </div>
      </div>
      <AllContacts />
    </>
  )
}
