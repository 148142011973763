import React from "react";
import BluredImage from "./BluredImage";
import Icon from "@mdi/react";
import { mdiBed, mdiBathtub, mdiHome, mdiLandPlots } from "@mdi/js";

export default function OneRealEstate({
  imageLink,
  title,
  location,
  price,
  bedrooms,
  bathrooms,
  inhabitableArea,
  landArea,
  onClick,
}) {
  const formatPrice = (price) => {
    const newPrice =
      price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €";
    return newPrice;
  };
  const newPrice = formatPrice(price);

  return (
    <div
      className="bg-gray-100 cursor-pointer rounded-lg p-4 hover:bg-gray-200 fade-transition"
      onClick={onClick}
    >
      <BluredImage imageLink={imageLink} />
      <h3 className="text-blue-900 font-bold line-clamp-1 text-xl lg:text-2xl mt-2">
        {title}
      </h3>
      <h4 className="line-clamp-1 text-lg lg:text-xl">{location}</h4>
      <p className="line-clamp-1 text-lg lg:text-xl mt-2">{newPrice}</p>
      <div className="lg:grid grid-cols-4 gap-4 mt-2">
        <div className="flex flex-row gap-2">
          <Icon path={mdiBed} size={1} />
          <p className="text-lg">{bedrooms}</p>
        </div>
        <div className="flex flex-row gap-2">
          <Icon path={mdiBathtub} size={1} />
          <p className="text-lg">{bathrooms}</p>
        </div>
        <div className="flex flex-row gap-2">
          <Icon path={mdiHome} size={1} />
          <p className="text-lg">{inhabitableArea} m²</p>
        </div>
        <div className="flex flex-row gap-2">
          <Icon path={mdiLandPlots} size={1} />
          <p className="text-lg">{landArea} m²</p>
        </div>
      </div>
    </div>
  );
}
