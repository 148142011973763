import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import AllContacts from "../AllContacts";
import AllRealEstate from "../AllRealEstate";
import { useNavigate, ScrollRestoration } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";

export default function Home() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [realEstateList, setRealEstateList] = useState([]);

  const realEstate = async () => {
    setLoading(true);
    const res = await fetch("/api/realestate");
    const data = await res.json();
    setLoading(false);
    setRealEstateList(data);
  };

  useEffect(() => {
    realEstate();
  }, []);

  const goToListing = (id) => {
    navigate("/listing/" + id);
  };

  return (
    <>
      <ScrollRestoration />
      <Navbar />
      <div className="mt-28 md:mt-36 mb-12 mx-6 md:mx-12">
        {loading ? (
          <Icon className="animate-spin" path={mdiLoading} size={5} />
        ) : (
          <AllRealEstate
            realEstateList={realEstateList}
            onClick={goToListing}
          />
        )}
      </div>
      <AllContacts />
    </>
  );
}
