import React from "react";

export default function BluredImage({ imageLink }) {
  return (
    <div className="w-full relative overflow-hidden aspect-3/2 rounded-lg">
      <div
        className="blur-bg"
        style={{ backgroundImage: "url('" + imageLink + "')" }}
      />
      <div
        className="image-bg"
        style={{ backgroundImage: "url('" + imageLink + "')" }}
      />
    </div>
  );
}
