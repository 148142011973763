import React, { useState } from "react";
import Icon from "@mdi/react";
import { mdiLoading, mdiCheck } from "@mdi/js";

export default function ContactForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const sendForm = async () => {
    setLoading(true);
    const bodyJSON = { name, email, phone, message };
    const res = await fetch("/api/contactform", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodyJSON),
    });
    setLoading(false);
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
    if (res.status !== 200) {
      return alert("Error while sending email.");
    }
    setSuccess(true);
  };
  return (
    <>
      <div className="flex flex-col md:grid grid-cols-3 gap-2">
        <input
          className="px-3 py-2 rounded-xl outline-none shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-sky-500 focus:ring-2 fade-transition"
          placeholder="Jméno a příjmení"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          className="px-3 py-2 rounded-xl outline-none shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-sky-500 focus:ring-2 fade-transition"
          placeholder="E-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className="px-3 py-2 rounded-xl outline-none shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-sky-500 focus:ring-2 fade-transition"
          placeholder="Telefon"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <textarea
          className="col-span-3 h-36 px-3 py-2 rounded-xl outline-none shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-sky-500 focus:ring-2 fade-transition"
          placeholder="Zpráva"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      <div className="mt-4 flex flex-row justify-end items-center gap-4">
        {loading ? (
          <Icon
            className="text-sky-200 animate-spin"
            path={mdiLoading}
            size={1.5}
          />
        ) : null}
        {success ? (
          <Icon className="text-sky-500" path={mdiCheck} size={1.5} />
        ) : null}
        <button
          className="px-3 py-2 rounded-xl bg-sky-500 text-white hover:bg-white hover:text-sky-500 disabled:bg-gray-300 disabled:text-gray-100 fade-transition"
          disabled={loading || success}
          onClick={sendForm}
        >
          Odeslat
        </button>
      </div>
    </>
  );
}
