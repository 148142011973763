import React, { useCallback, useEffect, useState } from "react";
import Navbar from "../Navbar";
import AllContacts from "../AllContacts";
import { useParams } from "react-router";
import Icon from "@mdi/react";
import {
  mdiLoading,
  mdiChevronLeft,
  mdiChevronRight,
  mdiBed,
  mdiBathtub,
  mdiHome,
  mdiLandPlots,
  mdiCircle,
} from "@mdi/js";
import BluredImage from "../BluredImage";

export default function Listing() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [listingData, setListingData] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);

  const formatPrice = (price) => {
    const newPrice =
      price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €";
    return newPrice;
  };

  const realEstate = useCallback(async () => {
    setLoading(true);
    const res = await fetch("/api/realestate/" + id);
    let data = await res.json();
    setLoading(false);
    let newListingData = data[0];
    newListingData.price = formatPrice(newListingData.price);
    setListingData(newListingData);
  }, [id]);

  useEffect(() => {
    realEstate();
  }, [realEstate]);

  const imageLeft = () => {
    if (currentImage === 0) {
      return setCurrentImage(listingData.imageLinks.length - 1);
    }
    setCurrentImage(currentImage - 1);
  };

  const imageRight = () => {
    if (currentImage === listingData.imageLinks.length - 1) {
      return setCurrentImage(0);
    }
    setCurrentImage(currentImage + 1);
  };

  return (
    <>
      <Navbar />
      <div className="mt-28 md:mt-36 mb-12 mx-6 md:mx-12">
        {loading ? (
          <Icon className="animate-spin" path={mdiLoading} size={5} />
        ) : (
          <div className="flex flex-col gap-8 lg:flex-row items-start">
            <div className="w-full lg:w-3/5 flex flex-row items-center relative">
              <div
                className="cursor-pointer rounded-full bg-white absolute left-2 top-1/2 transform -translate-y-1/2 z-10"
                onClick={imageLeft}
              >
                <Icon path={mdiChevronLeft} size={2} />
              </div>
              <div className="w-full flex flex-col items-center gap-2">
                <BluredImage imageLink={listingData.imageLinks[currentImage]} />
                <div className="grid grid-cols-12 gap-2">
                  {listingData.imageLinks.map((el, i) => (
                    <div
                      className="cursor-pointer"
                      onClick={() => setCurrentImage(i)}
                    >
                      <Icon
                        className={i === currentImage ? null : "text-gray-400"}
                        path={mdiCircle}
                        size={0.5}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="cursor-pointer rounded-full bg-white absolute right-2 top-1/2 transform -translate-y-1/2 z-10"
                onClick={imageRight}
              >
                <Icon path={mdiChevronRight} size={2} />
              </div>
            </div>
            <div className="w-full lg:w-2/5">
              <h2 className="text-4xl text-blue-900 font-bold">
                {listingData.title}
              </h2>
              <h3 className="text-3xl">{listingData.location}</h3>
              <p className="text-3xl mt-2">{listingData.price}</p>
              <div className="lg:grid grid-cols-4 gap-4 mt-2">
                <div className="flex flex-row gap-2">
                  <Icon path={mdiBed} size={1} />
                  <p className="text-lg">{listingData.bedrooms}</p>
                </div>
                <div className="flex flex-row gap-2">
                  <Icon path={mdiBathtub} size={1} />
                  <p className="text-lg">{listingData.bathrooms}</p>
                </div>
                <div className="flex flex-row gap-2">
                  <Icon path={mdiHome} size={1} />
                  <p className="text-lg">{listingData.inhabitableArea} m²</p>
                </div>
                <div className="flex flex-row gap-2">
                  <Icon path={mdiLandPlots} size={1} />
                  <p className="text-lg">{listingData.landArea} m²</p>
                </div>
              </div>
              <p className="text-lg mt-2 whitespace-pre-line">
                {listingData.description}
              </p>
              <iframe
                className="w-full aspect-3/2 border-none mt-4"
                title="Map"
                loading="lazy"
                allowfullscreen
                referrerpolicy="no-referrer-when-downgrade"
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyB5Y6FeM_D6PyXBtn_xuZcoNRjEVIzr6P8
    &q=${listingData.mapQuery}&zoom=${listingData.mapZoom}`}
              ></iframe>
            </div>
          </div>
        )}
      </div>
      <AllContacts />
    </>
  );
}
