import React, { useState } from "react";
import Icon from "@mdi/react";
import { mdiMenu } from "@mdi/js";
import logo from "./img/logo.png";
import NavbarLink from "./NavbarLink";

export default function Navbar() {
  const [open, setOpen] = useState(false);
  const toggleMenu = () => {
    setOpen(!open);
  };
  return (
    <nav className="bg-sky-200 shadow fixed top-0 w-full py-4 px-4 md:px-12 z-10">
      <div className="flex items-center justify-end lg:justify-center py-4">
        <div className="flex-shrink-0 absolute left-8">
          <img className="w-44 md:w-60" src={logo} alt="vinefin.cz" />
        </div>
        <div onClick={toggleMenu} className="cursor-pointer lg:hidden mr-4">
          <Icon path={mdiMenu} size={1.3} />
        </div>
        <div className="hidden lg:flex lg:space-x-4 self-center">
          <NavbarLink to="/">Nemovitosti</NavbarLink>
          <NavbarLink to="/aboutus">O nás</NavbarLink>
          <NavbarLink to="/useful">Užitečné informace</NavbarLink>
          <NavbarLink
            onClick={() =>
              window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: "smooth",
              })
            }
            to="#"
          >
            Kontakt
          </NavbarLink>
        </div>
      </div>
      {open ? (
        <div className="pt-4 flex flex-col gap-2 lg:hidden">
          <NavbarLink to="/">Nemovitosti</NavbarLink>
          <NavbarLink to="/aboutus">O nás</NavbarLink>
          <NavbarLink to="/useful">Užitečné informace</NavbarLink>
          <NavbarLink
            onClick={() => {
              window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: "smooth",
              });
              setOpen(false);
            }}
            to="#"
          >
            Kontakt
          </NavbarLink>
        </div>
      ) : null}
    </nav>

  );
}
