import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const login = async (event) => {
    event.preventDefault();
    setLoading(true);
    const username = event.target.username.value;
    const password = event.target.password.value;
    const res = await fetch("/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
      credentials: "include",
    });
    setLoading(false);
    if (res.status !== 200) {
      return alert(res.status);
    }
    navigate("/admin");
  };

  return (
    <div className="mt-28 md:mt-36 mb-12 mx-6 md:mx-12">
      <div className="flex flex-col items-center">
        <h1 className="text-3xl mb-6">Login</h1>
        <form onSubmit={login}>
          <div className="flex flex-col gap-2">
            <input
              className="w-96 px-3 py-2 rounded-xl outline-none shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-sky-500 focus:ring-2 fade-transition"
              placeholder="Username"
              name="username"
            />
            <input
              className="w-96 px-3 py-2 rounded-xl outline-none shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-sky-500 focus:ring-2 fade-transition"
              placeholder="Password"
              name="password"
              type="password"
            />
            <button
              className="px-3 py-2 rounded-xl bg-sky-500 text-white hover:bg-gray-100 hover:text-sky-500 disabled:bg-gray-300 disabled:text-gray-100 fade-transition"
              disabled={loading}
              type="submit"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
