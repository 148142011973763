import React from "react";
import OneRealEstate from "./OneRealEstate";

export default function AllRealEstate({ realEstateList, onClick }) {
  return (
    <div className="flex flex-col md:grid grid-cols-3 gap-4">
      {realEstateList.map((el) => (
        <OneRealEstate
          key={el.id}
          onClick={() => onClick(el.id)}
          imageLink={el.imageLinks[0]}
          title={el.title}
          location={el.location}
          price={el.price}
          bedrooms={el.bedrooms}
          bathrooms={el.bathrooms}
          inhabitableArea={el.inhabitableArea}
          landArea={el.landArea}
        />
      ))}
    </div>
  );
}
