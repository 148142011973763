import React from "react";
import ContactForm from "./ContactForm";
import OneContact from "./OneContact";
import Icon from "@mdi/react";
import { mdiPhone, mdiEmail, mdiWeb } from "@mdi/js";

export default function AllContacts() {
  const IconTemplate = (path) => (
    <Icon className="text-black" path={path} size={1} />
  );
  return (
    <div className="bg-sky-200 px-12 pt-8 pb-12">
      <h1 className="text-3xl font-black mb-8">Máte dotaz? Napište nám</h1>
      <ContactForm />
      <div className="mt-8 grid md:grid-cols-3 gap-4">
        <OneContact
          name="Telefon"
          content="+420 775 072 622"
          icon={IconTemplate(mdiPhone)}
        />
        <OneContact
          name="E-mail"
          content="spanelskedomy@gmail.com"
          icon={IconTemplate(mdiEmail)}
        />
        <OneContact
          name="Web"
          content="spanelskedomy.cz"
          icon={IconTemplate(mdiWeb)}
        />
      </div>
    </div>
  );
}
