import React from "react";
import Strip from "./Strip";

export default function Article({ title, content }) {
  return (
    <div className="flex flex-col">
      <Strip>{title}</Strip>
      <p className="text-xl mt-4">{content}</p>
    </div>
  );
}
